<template>
    <div class="ServiceCenter">
        <!-- <h1>服务中心</h1> -->
        <img src="https://img.youstarplanet.com/h5/serviceCenter/ServiceCenter.png" alt="">
        <div class="content">
            <div class="service_content">
                <div>服务内容</div>
                <div></div>
                <div>产业平台，技术创新，一点接入，全链无忧</div>
                <ul>
                    <li>
                        <img src="https://img.youstarplanet.com/h5/serviceCenter/service_content1.svg" alt="">
                        <div>
                            <span>服务演艺文化行业上中下游板块</span>
                            <span>去产品库存，拓收入渠道</span>
                            <span>1.项目包装、商务代理</span>
                            <span>2.版权管理、商业变现</span>
                            <span>3.数字产品开发</span>
                            <span>4.信息数据服务</span>
                        </div>
                    </li>
                    <li>
                        <img src="https://img.youstarplanet.com/h5/serviceCenter/service_content2.svg" alt="">
                        <div>
                            <span>不断满足各方演艺文化内容需求</span>
                            <span>省时、省力、省钱、省心</span>
                            <span>1.资源高效配置、信息公开透明</span>
                            <span>2.专业、专一、专注，提供体系化解决方案</span>
                            <span>3.去中间环节，以资源体系优势降低边际成本</span>
                            <span>4.结果导向，体系化运作，高行业服务标准</span>
                        </div>
                    </li>
                    <li>
                        <img src="https://img.youstarplanet.com/h5/serviceCenter/service_content3.svg" alt="">
                        <div>
                            <span>健全社会公共文化服务体系</span>
                            <span>创新基层公共文化服务方式</span>
                            <span>1.基层数据调研</span>
                            <span>2.打造头部文化品牌，塑造城市文化名片</span>
                            <span>3.提供一揽子解决方案</span>
                        </div>
                    </li>
                    <li>
                        <img src="https://img.youstarplanet.com/h5/serviceCenter/service_content4.svg" alt="">
                        <div>
                            <span>不断满足大众娱乐及文化消费需求</span>
                            <span>保障人人享有基本文化生活权利</span>
                            <span>1.从无到有、从有到优</span>
                            <span>2.品类丰富、价格实惠</span>
                            <span>3.平台化、数字化、常态化</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.ServiceCenter{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        padding: 0px 14px 84px;
        .service_content{
            &>div{
                &:nth-child(1){
                    margin-top: 38px;
                    margin-bottom: 22px;
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    text-align: center;
                }
                &:nth-child(2){
                    width: 28px;
                    height: 3px;
                    background-color: #D00A13;
                    margin: 0 auto;
                }
                &:nth-child(3){
                    margin-top: 21px;
                    margin-bottom: 19px;
                    font-size: 14px;
                    color: #888888;
                    font-weight: 400;
                    text-align: center;
                }
            }
            ul{
                li{
                    background-color: #FBFBFB;
                    width: 100%;
                    height: 183px;
                    box-sizing: border-box;
                    padding: 17px 15px;
                    display: flex;
                    margin-top: 9px;
                    &:nth-child(1){
                        margin-top: 0;
                    }
                    img{
                        width: 47px;
                        height: 47px;
                        margin-right: 21px;
                    }
                    div{
                        display: flex;
                        flex-direction: column;
                        span{
                            font-size: 12px;
                            color: #848484;
                            font-weight: 400;
                            &:nth-child(1){
                                font-size: 16px;
                                color: #000;
                                font-weight: bold;
                            }
                            &:nth-child(2){
                                font-size: 13px;
                                margin: 6px 0 14px 0;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .service_process{
            margin-top: 71px;
        }
        .project_application{
            margin-top: 106px;
            &>div{
                &:nth-child(1){
                    text-align: center;
                    font-size: 28px;
                }
                &:nth-child(2){
                    margin-top: 45px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 345px;
                    }
                    a{
                        position: absolute;
                        left: 515px;
                        top: 151px;
                        width: 240px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 18px;
                        color: #FFFFFF;
                        background-color: #1990FF;
                    }
                }
            }
        }
        .need_to_register{
            margin-top: 106px;
            margin-bottom: 200px;
            &>div{
                &:nth-child(1){
                    text-align: center;
                    font-size: 28px;
                }
                &:nth-child(2){
                    margin-top: 45px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 345px;
                    }
                    a{
                        position: absolute;
                        left: 515px;
                        top: 151px;
                        width: 240px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 18px;
                        color: #FFFFFF;
                        background-color: #F4397E;
                    }
                }
            }
        }
    }
}
</style>


